<template>
  <div class="sidebar-header">
    <a-tooltip :title="isCollapse ? '展开' : '收起'">
      <Focusable size="large" @click="onCollapse()">
        <i
          :class="
            isCollapse ? 'ndl-icon-menu-unfold-line' : 'ndl-icon-menu-fold-line'
          "
        ></i>
      </Focusable>
    </a-tooltip>
    <AsideHeaderSearch />
    <a-tooltip title="新建项目">
      <Focusable size="large" @click="onAdd()">
        <i class="ndl-icon-plus"></i>
      </Focusable>
    </a-tooltip>
  </div>
</template>

<script>
import { ui as uiState } from "@/store";
import Focusable from "../../focusable";
import AsideHeaderSearch from "./AsideHeaderSearch";

export default {
  components: { Focusable, AsideHeaderSearch },
  computed: {
    isCollapse() {
      return uiState.float;
    }
  },
  methods: {
    onAdd() {
      this.$router.push({ name: "datamodel-welcome" });
    },
    onCollapse() {
      uiState.float = !uiState.float;
      uiState.visible = false;
    }
  }
};
</script>

<style lang="less" scoped>
.sidebar-header {
  flex-shrink: 0;
  white-space: nowrap;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  transition: all 0.2s;
}
</style>
