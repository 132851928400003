<template>
  <div v-resizable class="sidebar">
    <AsideHeader :class="{ 'has-scrollbar': hasScrollbar }" />
    <DatasheetTree class="content" @scroll.native="onScroll" />
  </div>
</template>

<script>
import { resizable } from "@/utils/directives";
import AsideHeader from "./AsideHeader";
import DatasheetTree from "./DatasheetTree";

export default {
  components: { AsideHeader, DatasheetTree },
  directives: { resizable },
  data() {
    return {
      hasScrollbar: false
    };
  },
  methods: {
    onScroll(evt) {
      this.hasScrollbar = !!evt.target.scrollTop;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.sidebar {
  width: @sidebar-width;
  min-width: @sidebar-width - 80px;
  max-width: @sidebar-width + 120px;
  // border-right: solid 1px @ndl-border-color;
  // background: rgb(247, 246, 243);
  background: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06);
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  .has-scrollbar {
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.08);
  }
  .content {
    height: 0;
    flex: 1;
    overflow: auto;
    padding-top: 12px;
  }
}
</style>
