<template>
  <a-dropdown v-model="visibleResult" :trigger="['contextmenu']">
    <!-- 搜索框 -->
    <a-input
      class="search-box"
      placeholder="快速查找..."
      @input="debouncedInput"
      @focus="visibleResult = true"
      @blur="visibleResult = false"
      @contextmenu.capture.stop
    >
      <i slot="prefix" class="ndl-icon-search"></i>
    </a-input>
    <!-- 搜索结果 -->
    <div slot="overlay" class="ant-dropdown-menu">
      <div
        v-for="node in resultList"
        :key="node.nodeId"
        class="search-content-item"
        @mousedown="toViewHandler(node)"
      >
        <Iconfont :type="node.icon" class="search-content-item-icon"></Iconfont>
        <div class="search-content-item-content">
          <span class="search-content-item-title text-ellipsis">
            {{ node.nodeName }}
          </span>
          <span class="search-content-item-description text-ellipsis">
            {{ node.parent | parentsPath }}
          </span>
        </div>
      </div>
      <!-- 空数据提示 -->
      <a-empty
        v-if="!resultList.length && searchStr"
        class="none-data"
        description="无匹配视图"
        :image="ImageEmpty"
        :imageStyle="{ height: '150px', marginTop: '30px' }"
      />
    </div>
  </a-dropdown>
</template>

<script>
import Iconfont from "@/components/iconfont";
import debounce from "lodash/debounce";
import workspace from "@/views/datamodel/models/workspace";
import ImageEmpty from "@/assets/images/ai_no_data2.svg";
export default {
  components: { Iconfont },
  data() {
    return {
      searchStr: "", // 搜索字符串
      debouncedInput: debounce(this.inputSearchStr, 300), // 防抖
      visibleResult: false, // 搜索结果是否展示
      ImageEmpty: ImageEmpty
    };
  },
  computed: {
    resultList() {
      return this.toSearch(this.searchStr) || [];
    }
  },
  filters: {
    parentsPath(currentNode) {
      const path = [];
      while (currentNode) {
        path.unshift(currentNode.nodeName);
        currentNode = currentNode.parent;
      }
      path.shift();
      return path.join(" / ") || "未知";
    }
  },
  methods: {
    inputSearchStr(event) {
      this.searchStr = event.target.value || "";
    },

    toViewHandler(node) {
      this.$router.push({
        name: "datamodel-workspace",
        params: {
          workspaceId: node.parent.nodeId,
          datasheetId: node.nodeId
        }
      });
    },

    toSearch(searchStr) {
      searchStr = searchStr.replaceAll(" ", "");
      const workspaceList = workspace.children;
      if (!searchStr || !workspaceList || !workspaceList.length) {
        return;
      }
      return this.match(workspaceList, searchStr);
    },

    /**
     * 匹配
     * @param {Array} list 进行匹配的数组
     * @param {String} searchStr 搜索字符串
     * @param {Array} resultList 记录搜索结果
     * @returns {Array} 返回搜索结果
     */
    match(list = [], searchStr = "", resultList = []) {
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        let nodeName = item.nodeName.replaceAll(" ", "");
        if (!item.isFolder && nodeName.includes(searchStr)) {
          resultList.push(item);
        }
        if (item.children && item.children.length) {
          resultList = this.match(item.children, searchStr, resultList);
        }
      }
      return resultList;
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/datamodel/assets/vars.less";

.search-box {
  flex: 1 1 0;
  margin: 0 6px;
  /deep/ .ant-input-prefix {
    color: @text-color-secondary;
  }
  /deep/ .ant-input {
    background: none;
    border: none;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: @text-color-secondary;
    }
    &:hover,
    &:focus {
      background: @bg-focused;
    }
  }
  /deep/ .ant-select-selection {
    background: none;
  }
}
.ant-dropdown-menu {
  padding: 8px 0;
  max-height: 430px;
  overflow: auto;
  background-clip: border-box;
}
.search-content-item {
  min-width: 300px;
  margin: 0 8px;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background: #f0f0f0;
  }
  &-icon {
    height: 28px !important;
    width: 28px !important;
  }
  &-content {
    flex: 1;
    margin-left: 10px;
  }
  &-title {
    font-size: 14px;
  }
  &-description {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.45);
  }
  .text-ellipsis {
    display: block;
    max-width: 400px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.none-data {
  width: 300px;
  padding-bottom: 20px;
}
</style>
