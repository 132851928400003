<template>
  <component
    :is="dragComponent"
    group="workspace"
    :animation="200"
    :delay="dragDelay"
    @sort="onSort"
  >
    <div
      v-if="showEmptyTips"
      style="color: #bfbfbf; margin-top: 5em; text-align: center"
    >
      暂无数据
    </div>
    <Tree
      v-for="item in workspaceList"
      :key="item.nodeId"
      :node="item"
      :active-nodes="activeNodes"
      @select="onSelect"
      @add="onAdd"
      @contextmenu="onContextmenu"
    />
  </component>
</template>

<script>
import Draggable from "vuedraggable";
import store from "@/store";
import showContextmenu from "@/components/contextmenu";
import Tree from "@/views/datamodel/components/tree";
import workspace from "@/views/datamodel/models/workspace";
import NodeOptions from "./node-options";

export default {
  components: { Draggable, Tree },
  computed: {
    workspaceList() {
      return workspace.children;
    },
    showEmptyTips() {
      if (workspace.nodeStatus.loading) {
        return false;
      }
      return !workspace.children.length;
    },
    dragComponent() {
      const user = store.userInfo || { isMainAdmin: false, isSubAdmin: false };
      return user.isMainAdmin || user.isSubAdmin ? "Draggable" : "div";
    },
    dragDelay() {
      return "ontouchstart" in document.documentElement ? 100 : 0;
    },
    activeNodes() {
      const { workspaceId, datasheetId } = this.$route.params;
      const nodes = [];
      if (workspaceId) {
        nodes.push(workspaceId);
      }
      if (datasheetId) {
        nodes.push(datasheetId);
      }
      return nodes;
    }
  },
  methods: {
    onSort({ oldIndex = 0, newIndex = 0 }) {
      const list = this.workspaceList;
      list.splice(newIndex, 0, list.splice(oldIndex, 1)[0]);
      workspace.updateChildrenPosition(list);
    },
    onSelect(node) {
      if (!node.isFolder) {
        this.$router.push({
          name: "datamodel-workspace",
          params: {
            workspaceId: node.parent.nodeId,
            datasheetId: node.nodeId
          }
        });
        return;
      }
      const defaultSheet = node.children[0];
      if (defaultSheet) {
        this.$router.push({
          name: "datamodel-workspace",
          params: { workspaceId: node.nodeId, datasheetId: defaultSheet.nodeId }
        });
      } else {
        this.$router.push({
          name: "datamodel-workspace-guide",
          params: { workspaceId: node.nodeId }
        });
      }
    },
    onAdd(node) {
      this.$router.push({
        name: "datamodel-workspace-guide",
        params: { workspaceId: node.nodeId }
      });
    },
    onContextmenu(node, evt) {
      let options = node.isFolder
        ? NodeOptions.getFolderOptions(node)
        : NodeOptions.getFileOptions(node);
      const source = evt.type === "contextmenu" ? evt : evt.target;
      showContextmenu({ options, width: "160px" }, { source });
    }
  }
};
</script>
