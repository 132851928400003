<template>
  <section class="workbench-wrapper ndl-flex">
    <Aside :class="['ndl-flex-item--noshrink', uiState]" />
    <!-- 用于触发弹出侧边栏动作 -->
    <div
      v-if="uiState.float"
      class="stage-edge"
      @mouseenter="onShowAside()"
    ></div>
    <div class="ndl-overflow ndl-flex-item--grow" @mouseenter="onHideAside()">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import store, { ui as uiState } from "@/store";
import workspace from "./models/workspace";
import Aside from "./components/aside";

export default {
  components: { Aside },
  computed: {
    uiState() {
      return uiState;
    }
  },
  /**
   * 默认打开 userInfo 中记录的最近一次访问的视图
   */
  beforeRouteEnter(to, from, next) {
    // 初始化 workspace model
    workspace.init().then(() => {
      const params = to.params;
      workspace.currentWorkspaceId = params.workspaceId || null;
      workspace.currentDatasheetId = params.datasheetId || null;
      workspace.currentDatasheet.init();
    });
    // 根据 userInfo 中的信息判断是否需要重定向至上次打开的表格
    const routeName = "datamodel-workspace";
    if (to.name === routeName) {
      return next();
    }
    const { activeFolderId, activeDatasheetId } = store.userInfo || {};
    if (activeFolderId && activeDatasheetId) {
      return next({
        name: routeName,
        params: {
          workspaceId: activeFolderId,
          datasheetId: activeDatasheetId
        }
      });
    }
    return next();
  },
  /**
   * 路由变化时记录最近一次访问的视图信息
   */
  beforeRouteUpdate(to, from, next) {
    const paramsTo = to.params;
    const paramsFrom = from.params;
    // 进入新的文件夹前，关闭旧文件夹下的表格连接
    if (paramsTo.workspaceId !== paramsFrom.workspaceId) {
      workspace.closeDatasheets();
    }
    // 记录最近一次打开的表格信息
    if (paramsTo.workspaceId && paramsTo.datasheetId) {
      const userInfo = store.userInfo || {};
      userInfo.activeFolderId = paramsTo.workspaceId;
      userInfo.activeDatasheetId = paramsTo.datasheetId;
    }
    // 更新 workspace 中的 currentWorkspaceId 和 currentDatasheetId 以便初始化新的表格
    workspace.currentWorkspaceId = paramsTo.workspaceId || null;
    workspace.currentDatasheetId = paramsTo.datasheetId || null;
    workspace.currentDatasheet.init();
    next();
  },
  beforeRouteLeave(to, from, next) {
    uiState.float = false;
    workspace.activePane = null;
    workspace.closeDatasheets();
    next();
  },
  methods: {
    onShowAside() {
      uiState.visible = true;
    },
    onHideAside() {
      uiState.visible = false;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.workbench-wrapper {
  height: 100%;
  overflow: hidden;

  .sidebar {
    transition: transform 0.2s, left 0.2s;
    &.float {
      position: fixed;
      // 比 .stage-edge 的 z-index 高一点，侧边栏展开后就能遮住 .stage-edge 了
      z-index: @datasheet-loading-mask-zindex + 3;
      top: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-110%);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
    }
    &.float.visible {
      left: @navbar-width;
      transform: translateX(0);
    }
  }
  .stage-edge {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 10px;
    // 比加载动画 mask 的 z-index 高一层，便于加载中也能触发侧边栏
    z-index: @datasheet-loading-mask-zindex + 1;
  }
}
</style>
